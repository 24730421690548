export default {
  date: "8-09-23",
  env_restriction: [],
  content: [
    {
      module: "Fixes",
      updates: [
        "Users can now perform the API Get Project call by using the address on the project",
        "Removed the filename extension validation when uploading a plan set",
        "Updated the Wet Stamp details in “Edit Details” modal",
        "Migration banner updated",
        "Users are now allowed to upgrade their project to include IEBC (for specific project combination like IEBC SP)",
        "Fixed Issue with Photo Download",
        "Refactor and enhanced the Client’s Page based on the updated UI/UX design"
      ]
    }
  ]
};
