export default {
  namespaced: true,
  state: {
    items: null,
    requester: null,
    item: null,
    shipping_profiles: []
  },
  getters: {
    requester_name: state => {
      return state.requester.full_name;
    }
  },
  mutations: {
    SET_PROJECT_WETSTAMP: (state, payload) => {
      state.items = payload.project_wetstamp_requests;
      state.requester = payload.creator;
    },
    SET_WETSTAMP_ITEM_SHIPPING_PROFILE: (state, payload) => {
      state.item = payload.item;
    },
    SET_SHIPPING_PROFILES: (state, payload) =>
      (state.shipping_profiles = payload)
  },
  actions: {}
};
