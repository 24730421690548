import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    activities: [],
    item: {}
  },
  getters: {},
  mutations: {
    SET_ACTIVITY: (state, payload) => (state.item = payload),
    SET_ACTIVITIES: (state, payload) => (state.activities = payload)
  },
  actions: {
    getData: async ({ rootState, commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`/activities`, {
          params: {
            project_id: rootState.projectOverview.project.project.id
          }
        });
        const activities = _.get(response, "data.result");

        commit("SET_ACTIVITIES", activities);

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
