import _ from "lodash";
import api from "@/api";

const formdata = {
  state: "",
  signed_stamp: null,
  unsigned_stamp: null,
  stamp_size: "",
  expiration_date: ""
};

export default {
  namespaced: true,
  state: {
    data: [],
    meta: null,
    formdata: {
      state: "",
      signed_stamp: "",
      unsigned_stamp: "",
      stamp_size: "",
      expiration_date: ""
    },
    filters: {
      term: "",
      nearing_expired: false
    }
  },
  getters: {},
  mutations: {
    SET_FORMDATA: (state, payload) => {
      state.formdata = payload;
    },
    SET_FILTERS: (state, payload) => {
      for (const key in payload) {
        state.filters[key] = payload[key];
      }
    },
    SET_INITIAL_FORMDATA: state => {
      state.formdata = formdata;
    },
    SET_DATA: (state, payload) => {
      state.data = payload.data;
      state.meta = payload.meta;
    }
  },
  actions: {
    async index({ state, commit }, page) {
      const params = {
        ...state.filters,
        page
      };

      const response = await api.get(`/stamps`, { params });
      const results = _.get(response, "data.result", []);

      commit("SET_DATA", results);
    }
  }
};
