import _ from "lodash";
import api from "@/api";

const initial_group = {
  name: "",
  company: {},
  user: {},
  address: {
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: ""
  }
};

export default {
  namespaced: true,
  state: {
    list: [],
    meta: null,
    group: initial_group,
    show_suspended: false,
    term: ""
  },
  mutations: {
    SET_SEARCH_TEXT: (state, payload) => {
      state.term = payload;
    },
    SET_GROUPS_DATA: (state, payload) => {
      state.list = payload.data;
      state.meta = payload.meta;
    },
    SET_INITIAL_STATE: state => {
      state.group = initial_group;
    },
    SET_SHOW_SUSPENDED: (state, payload) => {
      state.show_suspended = payload;
    },
    SET_GROUP_TO_EDIT: (state, payload) => (state.group = payload),
    SET_PAGE: (state, payload) => {
      state.page = payload;
    }
  },
  actions: {
    getGroups: async ({ state, commit }, page_number) => {
      commit("SET_PAGE", page_number);

      const users_data = await api.get("/groups", {
        params: {
          page: page_number,
          per_page: 10,
          // TODO: set show_suspended to BOOLEAN
          show_suspended: state.show_suspended ? 1 : "",
          term: state.term
        }
      });

      const data = _.get(users_data, "data.result", []);

      commit("SET_GROUPS_DATA", data);
    }
  }
};
