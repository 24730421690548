import pageAccessControls from "@/middleware/pageAccessControls";
import initializeCreateProject from "@/middleware/initializeCreateProject";
import loadProjectTypes from "@/middleware/loadProjectTypes";
import loadProjectStatuses from "@/middleware/loadProjectStatuses";

export default {
  name: "CreateProject",
  path: "/create-project",
  component: () => import("@/views/CreateProject"),
  meta: {
    middleware: [
      pageAccessControls,
      initializeCreateProject,
      loadProjectTypes,
      loadProjectStatuses
    ]
  }
};
