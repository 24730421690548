import _ from "lodash";
import api from "@/api";
import DimensionsStructure from "./structure_dimensions.js";

export default {
  namespaced: true,
  state: {
    items: [],
    original_items: [],
    on_edit: false,
    dimensions_structure: DimensionsStructure
  },
  getters: {},
  mutations: {
    SET_EQUIPMENT_ITEMS: (state, payload) => {
      let add_dimensions = payload.map(item => {
        if (_.isNil(item.dimensions)) {
          item.dimensions = state.dimensions_structure;
          item["error"] = false;
        }

        return item;
      });

      state.items = [].concat(add_dimensions);
      state.original_items = [].concat(add_dimensions);
    },
    UPDATE_EQUIPMENT_ITEM: (state, payload) => {
      const { item, index } = payload;

      state.items.splice(index, 1, item);
    },
    TOGGLE_EDIT: (state, payload) => (state.on_edit = payload),
    REVERT_ITEMS: state => (state.items = _.cloneDeep(state.original_items)),
    UPDATE_ITEM_ERROR: (state, payload) => {
      const { index, error } = payload;
      state.items[index].error = error;
    }
  },
  actions: {
    getData: async ({ rootState, commit }) => {
      const project_id = rootState.projectOverview.project.project.id;

      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(
          `/project-equipments/get-by-project/${project_id}`
        );
        const items = _.get(response, "data.result");

        commit("SET_EQUIPMENT_ITEMS", items);

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
