import api from "@/api";
import overview from "./overview";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    list: [],
    meta: null
  },
  getters: {},
  mutations: {
    SET_PROJECTS_LIST: (state, payload) => {
      state.list = payload;
    },
    SET_PROJECTS_META: (state, payload) => {
      state.meta = payload;
    }
  },
  actions: {
    getData: async ({ commit }, page) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const params = { page };

        const result = await api.get(`/project-importer`, {
          params
        });

        const items = _.get(result, "data.data", {});
        const meta = _.get(result, "data.meta", {});

        commit("SET_PROJECTS_LIST", items);
        commit("SET_PROJECTS_META", meta);

        return result;
      } catch (error) {
        throw error;
      }
    }
  },
  modules: {
    overview
  }
};
