import _concat from "lodash/concat";
import createProject from "./createProject";
import pageAccessControls from "@/middleware/pageAccessControls";
import loadProjectAddons from "@/middleware/loadProjectAddons";

export default {
  path: "/create-project",
  component: () => import("@/layouts/FullLayout"),
  children: _concat(createProject),
  meta: {
    middleware: [pageAccessControls, loadProjectAddons]
  }
};
