export default {
  date: "10-30-23",
  env_restriction: [],
  content: [
    {
      module: "Feature release",
      updates: [
        "Introduced new filtering functionality to improve ease of use and accuracy of searches. Users can now filter by 'State', 'City', and 'Country'.",
        "Implemented a system improvement in the Create Project function to ensure accuracy in project location. From now on, before moving to the next screen clients must need to select the Google Suggested Address instead of manually entering it. The 'Next' button will be enabled once the suggested address, building description and surrounding terrain have been selected. An error prompt will also appear if further entry attempts are made after selecting an address. As an alternative, clients can also drag the location point. This update will streamline the project set-up process and prevent location related errors."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Updated the default sort order for the \"Member Since\" column to show the most recent clients first for more efficient client management. Enhanced visibility of the 'Member Since' column by moving it to the left side.",
        "Enhanced the Project List view, A simple click on any project row will now open the relative project-to-project view.  Introduced labeled icons for more user-friendly navigation on the Project List page.Simplified the method of accessing archived projects. Now, the archive button action is to toggle view of the current project from active to archived and vice versa. Tikbox removed for Terms and Privacy Policy.  Add funds is always enabled.  Fixed modal on add funds redundant prompt.",
        "Fixed company dropdown issue, it now displays the entire list, including recently signed-up companies.",
        "Enhanced Wet Stamp view by incorporating an informational message which states 'No wet stamp requests for this project as of the moment' when there are no wet stamp requests associated with a project. This ensures clear communication and eliminates any confusion related to blank areas without information.",
        "Updated 'Add Funds' button, represented by a green circle with a white plus sign, beside the balance visible only for Client Admin profiles. This update is aimed to  improve usability for new users. The button leads directly to the 'Add Funds' billing page. As such, the redundant 'Add Funds' button beside the balance has been removed.",
        "Enhancement to all New York projects to indicate a 'SC' stamp and are forwarded to the engineer, eliminating the generation of automatic IEBC letters. Mandatory IEBC letter is required upon project completion. Wind and Snow Value are set to 'SC' for standardized project review.",
        'Modified project creation process to include validation for state-specific requirements. Now, when a project is created with the address in New Jersey, a prompt indicating "Wet Stamp is required for New Jersey" appears to ensure compliance with the state\'s unique requirement.',
        "Resolved an issue where users encountered an 'Element already contains a Braintree iframe' error when trying to upload a balance. This bug was primarily affecting users without a registered card. Users are now able to successfully upload balances without encountering this problem.",
        "Implemented a fix to have the full project address reflected correctly in Create Project under Review Details.",
        "Removed invalid 'State' enums when updating projects to ensure data validity and avoid unexpected errors.",
        "Fixed an issue where project files would disappear from the Dropzone after being selected. Files are now consistently visible post-selection and are uploaded once the 'Next' button is clicked.",
        "Adjusted sorting for 'Member Since' column under the 'Clients' tab to list in descending order, with recent additions displayed first. This ensures that PZSE Coordinators can view and manage the most recently registered clients more efficiently.",
        "Adjusted alignment and size for better data visibility - wet stamp and address column data is now center aligned and the textbox for the address column has been enlarged. The unnecessary 'Legacy' column has been effectively removed."
      ]
    }
  ]
};
