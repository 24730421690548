import _ from "lodash";
/* import moment from "moment"; */
import api from "@/api";

export default {
  namespaced: true,
  state: {
    token: null,
    payment_methods: [],
    payment_method: null,
    confirm_details_dialog: false,
    payment_success_dialog: false,
    billing_details: {
      first_name: "",
      last_name: "",
      company_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postal_code: "",
      phone_number: "",
      client_id: 0
    },
    card_details: {
      card_number: null,
      card_name: null,
      cvv: null,
      exp_date: null
    },
    amount: "0.00",
    is_amount_valid: false,
    amount_reset: false,
    data_ready: false,
    data: [],
    meta: null,
    filters: {
      date_start: null,
      date_end: null,
      term: "",
      transaction_type: null,
      transaction_by: null,
      account_id: null,
      project_number: null
    }
  },
  mutations: {
    SET_PAYMENT_METHOD: (state, payload) => (state.payment_method = payload),
    SET_PAYMENT_METHODS: (state, payload) => (state.payment_methods = payload),
    SET_TOKEN: (state, payload) => (state.token = payload),
    SET_CONFIRM_DETAILS_DIALOG: (state, payload) =>
      (state.confirm_details_dialog = payload),
    SET_PAYMENT_SUCCESS_DIALOG: (state, payload) =>
      (state.payment_success_dialog = payload),
    SET_BILLING_DETAILS: (state, payload) => {
      state.billing_details = {
        ...payload
      };
    },
    SET_CARD_DETAILS: (state, payload) => {
      state.card_details = {
        ...payload
      };
    },
    SET_AMOUNT: (state, payload) => (state.amount = payload),
    SET_AMOUNT_VALID: (state, payload) => (state.is_amount_valid = payload),
    RESET_AMOUNT: (state, payload) => (state.amount_reset = payload),
    SET_FILTERS: (state, payload) => (state.filters = payload),
    SET_DATA_READY: (state, payload) => (state.data_ready = payload),
    SET_DATA: (state, payload) => {
      state.data = payload.data;
      state.meta = payload.meta;
    }
  },
  actions: {
    async index({ state, commit }, page_filters) {
      commit("SET_DATA_READY", false);

      const url = `/transactional-activities/v2`;
      const params = {
        page: page_filters.page,
        per_page: page_filters.per_page,
        term: state.filters.term,
        date_start: state.filters.date_start,
        date_end: state.filters.date_end,
        transaction_type: state.filters.transaction_type,
        transaction_by: state.filters.transaction_by,
        account_id: state.filters.account_id,
        project_number: state.filters.project_number
      };
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(url, { params });
        const results = _.get(response, "data.result", []);
        commit("SET_DATA", results);
      } catch (error) {
        throw error;
      }

      commit("SET_DATA_READY", true);
    }
  }
};
