import project from "./project";
import activity from "./activity";
import proposal_logs from "./proposal_logs";
import upgrade from "./upgrade";
import wetstamps from "./wetstamps";
import comments from "./comments";
import equipment from "./equipment";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    project,
    activity,
    proposal_logs,
    upgrade,
    wetstamps,
    comments,
    equipment
  }
};
