export default {
  date: "1-18-24",
  env_restriction: [],
  content: [
    {
      module: "Features",
      updates: [
        "Enhanced the export functionality in the Transactions Tab. Users with access to Transactions can now export lists of transactions per page. These can be filtered by transaction date and are available in CSV and XLSX formats.",
        "Refined the 'Add User' modal. Removed the redundant 'Company' field for PZSE client and vendor profiles. Editing of the company field is now limited to PZSE profiles only. Introduced a combo box feature for the company field to enhance user experience. Updated the client list to display up to 15 of the most recent clients. Resolved an issue where unrelated groups appeared in the Client Role; now, only groups belonging to the specific company are displayed.",
        "Updated Wet Stamp request logs. These are now linked to the requester instead of the project creator, offering clearer tracking of the wet stamp request history.",
        "Introduced a new feature for PZSE Engineers. A 'Stamps' tab has been added to the AHJ page, allowing engineers to view and update existing stamps. Engineers can also search for stamps by state. Stamps are listed by expiration date, with those nearing expiry at the top for immediate action."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Removed the client column from the project list for certain roles.",
        "Addressed an issue where the Vendor Manager role had access to the old users tab under accounts. This has been updated to the new user screen.",
        "Resolved a viewing issue for the Vendor Admin role regarding the users tab under accounts.",
        "Fixed a bug where engineers could bypass mandatory stamped plans for Full Structural projects. Now, an error is triggered when requirements are incomplete, preventing project completion.",
        "Rectified error 422, which occurred when posting an internal comment.",
        "Standardized address formatting in the Project List to align with industry standards.",
        "Removed the 'Send to QA' button as it has become obsolete.",
        "Client Notification is now enabled in user's profile, allowing administrators to receive updates on project progress after completing a one-time setup. Additionally, this update introduces a feature where administrators can add extra email recipients for various notifications, including those related to receipts and invoices, which is especially useful for the finance team. ",
        "The SaaS agreement in the PZSE portal has been updated, reflecting new service terms and conditions. Users are advised to review these changes to understand their implications on service usage."
      ]
    }
  ]
};
