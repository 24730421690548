export default {
  date: "1-03-24",
  env_restriction: [],
  content: [
    {
      module: "Features/Fixes",
      updates: [
        "Enhanced functionality to send notifications to clients' webhooks upon completion or redlining of their projects.",
        "Introduced a new, non-holiday banner on the portal, currently promoting complimentary upgrades from IEBC Letters to Full Calculations.",
        "Removed default coordinates when the 'Manually Input Coordinates' option is selected, allowing end users to easily add new coordinates.",
        "Updated the color scheme of the steppers on the Create Project page to better align with the PZSE portal theme.",
        "Resolved an issue where incorrect email formats were not identified. The system now indicates when the format does not adhere to standard email structures.",
        "Addressed an issue where the 'Created by' and 'Created Date' fields on the Project Overview Page appeared truncated. This fix significantly enhances the user interface.",
        "Updated the Create Project - Location functionality to display an error message when the location pin is dragged to an address outside the USA.",
        "Resolved an address validation issue caused by ATC downtime/maintenance. Now, if Google Maps validates an address but ATC is unresponsive, the project can proceed. This ensures that valid project submissions are not hindered by ATC downtime, with backend engineers prepared to address any discrepancies."
      ]
    }
  ]
};
