import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    types: []
  },
  getters: {},
  mutations: {
    SET_TYPES: (state, payload) => (state.types = payload)
  },
  actions: {
    getData: async ({ commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get("/providers/project-types");
        const types = _.get(response, "data.result", []);

        commit("SET_TYPES", types);

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
