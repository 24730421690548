import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    notification_settings_disabled: false
  },
  getters: {},
  mutations: {
    SET_NOTIFICATION_SETTING_PERMISSION: (state, payload) => {
      state.notification_settings_disabled = payload;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async markNotificationAsRead({ commit }, notificationId) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.patch(
          `/notifications/${notificationId}/read`
        );

        return _.get(response, "data.result", {});
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async markAllNotificationAsRead({ commit }, userId) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.patch(`/notifications/${userId}/readAll`);

        return _.get(response, "data.result", {});
      } catch (error) {
        throw error;
      }
    },

    // eslint-disable-next-line no-unused-vars
    async getNotificationSettings({ commit }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`/account-notif-settings/me`);

        return _.get(response, "data.result", {});
      } catch (error) {
        throw error;
      }
    }
  }
};
