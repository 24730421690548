import api from "@/api";
import _ from "lodash";

const initialState = () => ({
  formdata: {
    first_name: "",
    last_name: "",
    company_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    phone_number: ""
  },
  payload: {
    first_name: "",
    last_name: "",
    company_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    phone_number: "",
    client_id: 0
  },
  notifications: [
    {
      id: 1,
      label: "All email notifications",
      email: true,
      browser: false
    },
    {
      id: 2,
      label: "Project Completed",
      email: false,
      browser: false
    },
    {
      id: 3,
      label: "New Documents",
      email: false,
      browser: false
    },
    {
      id: 4,
      label: "New Comments",
      email: false,
      browser: false
    }
  ],
  shipping_profiles: null,
  shipping_profile_formdata: {
    shipping_profile_id: null,
    shipping_profile: {},
    set_default_shipping_profile: false,
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    phone_number: ""
  },
  documents: [
    {
      id: 1,
      label: "Unlocked Plansets from PZSE",
      value: true
    },
    {
      id: 2,
      label: "Company Name on letter, not username",
      value: false
    },
    {
      id: 3,
      label: "Begin letter with “Sincerely”",
      value: false
    },
    {
      id: 4,
      label: "Manual Positioning of Stamp “Beta”",
      value: true
    }
  ],
  is_shipping_profiles_ready: false
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    RESET_STATE: state => Object.assign(state, initialState()),

    SET_FORMDATA: (state, payload) => {
      state.formdata = payload;
    },

    SET_NOTIFICATIONS: (state, payload) => (state.notification = payload),

    SET_FORMDATA_SET_DEFAULT_SHIPPING_PROFILE: (state, payload) => {
      state.shipping_profile_formdata.set_default_shipping_profile = payload;
    },

    SET_FORMDATA_SHIPPING_PROFILE_FULL: (state, payload) => {
      state.shipping_profile_formdata = payload;
    },

    SET_FORMDATA_SHIPPING_PROFILE: (state, payload) => {
      const { contact, address, id, is_default } = payload;

      state.shipping_profile_formdata.shipping_profile = payload;

      state.shipping_profile_formdata.shipping_profile_id = id;
      state.shipping_profile_formdata.address_1 = address.address_1;
      state.shipping_profile_formdata.address_2 = address.address_2;
      state.shipping_profile_formdata.city = address.city;
      state.shipping_profile_formdata.state = address.state;
      state.shipping_profile_formdata.postal_code = address.postal_code;
      state.shipping_profile_formdata.first_name = contact.first_name;
      state.shipping_profile_formdata.last_name = contact.last_name;
      state.shipping_profile_formdata.phone_number = contact.phone_number;
      state.shipping_profile_formdata.email = contact.email;
      state.shipping_profile_formdata.company_name = contact.company_name;
      state.shipping_profile_formdata.set_default_shipping_profile = is_default;
    },

    SET_DOCUMENT_VALUE: (state, payload) => {
      const { id, value } = payload;
      const index = _.findIndex(state.documents, { id: id });
      state.documents[index].value = value;
    },

    SET_SHIPPING_PROFILES: (state, payload) =>
      (state.shipping_profiles = payload),

    SET_SHIPPING_PROFILES_READY: (state, payload) =>
      (state.is_shipping_profiles_ready = payload)
  },
  actions: {
    getProfile: async ({ state, commit }, id) => {
      let getProfileData = await api.get(`/users/` + id);

      let profileData = _.get(getProfileData, "data.result", []);

      state.payload.first_name = profileData.first_name;
      state.payload.last_name = profileData.last_name;
      state.payload.phone_number = profileData.phone_number;
      state.payload.company_name = profileData.account.company_name;
      state.payload.address_1 = profileData.address.address_1;
      state.payload.address_2 = profileData.address.address_2;
      state.payload.city = profileData.address.city;
      state.payload.state = profileData.address.state;
      state.payload.postal_code = profileData.address.postal_code;
      state.payload.client_id = profileData.account.id;

      commit("SET_FORMDATA", state.payload);
    },
    register: async (context, payload) => {
      // eslint-disable-next-line no-useless-catch
      try {
        return await api.post("/auth/register", payload);
      } catch (error) {
        throw error;
      }
    }
  }
};
