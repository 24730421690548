import loadProjectTypes from "@/middleware/loadProjectTypes";
import loadProjectStatuses from "@/middleware/loadProjectStatuses";

export default {
  name: "Projects",
  path: "/projects",
  component: () => import("@/views/Projects"),
  meta: {
    middleware: [loadProjectTypes, loadProjectStatuses]
  }
};
