import _ from "lodash";
import api from "@/api";
import projectTypes from "./projectTypes";
import projectStatuses from "./projectStatuses";
import projectAddons from "./projectAddons";

export default {
  namespaced: true,
  state: {
    internal_statuses: [],
    manufacturers_icon: [
      {
        key: 1,
        name: "simpson",
        icon: require(`@/assets/images/create-project/simpson.png`)
      },
      {
        key: 2,
        name: "hilti",
        icon: require(`@/assets/images/create-project/hilti.png`)
      },
      {
        key: 3,
        name: "dewalt",
        icon: require(`@/assets/images/create-project/dewalt.png`)
      }
    ]
  },
  getters: {},
  mutations: {
    SET_INTERNAL_STATUSES: (state, payload) =>
      (state.internal_statuses = payload)
  },
  actions: {
    getInternalStatuses: async ({ commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get("/providers/internal-statuses");
        const statuses = _.get(response, "data.result", []);

        const ordered_status = _.orderBy(statuses, ["value"], ["asc"]);

        commit("SET_INTERNAL_STATUSES", ordered_status);

        return response;
      } catch (error) {
        throw error;
      }
    }
  },
  modules: {
    projectTypes,
    projectStatuses,
    projectAddons
  }
};
