import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    async show({ commit }, userId) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`/users/${userId}`);

        return _.get(response, "data.result", {});
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async notifications({ commit }, userId) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`/users/${userId}/notifications`);

        return _.get(response, "data.result", {});
      } catch (error) {
        throw error;
      }
    }
  }
};
