import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    filters: {
      term: "",
      is_archived: null,
      with_wet_stamp: null,
      type: null,
      status: null,
      claimed: null,
      engineer_id: null,
      quality_control_id: null,
      account_id: null,
      created_by: null,
      created_at: null,
      archived_at: null,
      service_type: null
    },
    projects_meta: {},
    projects_data: [],
    sort: {
      by: "created_at",
      desc: true
    }
  },
  getters: {},
  mutations: {
    SET_FILTERS: (state, payload) => {
      for (const key in payload) {
        state.filters[key] = payload[key];
      }
    },
    SET_PROJECT_DATA: (state, payload) => {
      state.projects_data = payload.data;
      state.projects_meta = payload.meta;
    },
    SET_SORTER: (state, payload) => (state.sort = payload)
  },
  actions: {
    getData: async ({ state, commit }, page_filters) => {
      const params = {
        ...state.filters,
        page: page_filters.page,
        per_page: page_filters.per_page
      };

      const project = await api.get(`/projects/archived`, { params });

      let data = _.get(project, "data.result", []);
      commit("SET_PROJECT_DATA", data);
    }
  }
};
