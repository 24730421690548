import _concat from "lodash/concat";
import patchNotes from "./patchNotes";
import processPatchNotes from "@/middleware/processPatchNotes";

export default {
  path: "/patch-notes",
  component: () => import("@/layouts/FullLayout"),
  children: _concat(patchNotes),
  meta: {
    middleware: [processPatchNotes]
  }
};
