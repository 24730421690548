export default {
  namespaced: true,
  state: {
    show_service_list_and_price_dialog: false
  },
  getters: {},
  mutations: {
    SET_SERVICE_LIST_AND_PRICE_DIALOG: (state, payload) =>
      (state.show_service_list_and_price_dialog = payload.service)
  },
  actions: {
    toggleServiceListAndPriceDialog: ({ commit }, payload) =>
      commit("SET_SERVICE_LIST_AND_PRICE_DIALOG", payload)
  }
};
