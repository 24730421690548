export default {
  date: "9-04-23",
  env_restriction: [],
  content: [
    {
      module: "Feature release",
      updates: [
        "Removed 'Plan Check' and 'Standard' comment types to simplify the commenting process, enhancing user clarity and reducing confusion.",
        "Fixed an issue where the billing details were not populating in the 'Edit Details' modal interface. Customers will now be able to view and update their billing details as expected.",
        "Increased character limit in 'Project Notes' section to 2000 to accommodate larger notes and improve communication, ensuring project details do not get omitted due to character count restrictions."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Address auto-fill feature has been improved to correctly parse data, removed other fields such as City, State and Zip for enhanced user experience.",
        "Updated functionality for 'Edit Address' field in Create Project, now enabling a lookup trigger.",
        "Updated project creation functionality, allowing users to create project without attachment requirements.",
        "Implemented the consistency in project dropzone size across all project types, including single or combination projects.",
        "Fixed an issue where the complete address was not updating properly on the Billing Details page. Now, when an address is updated via Google Lookup, the entire address, including City, State, and Zip will correctly reflect the changes.",
        "Resolved the IEBC SP itemization discrepancy for the IEBC SP project type, preventing the simultaneous selection of FS and IEBC, which was causing the itemization to erroneously include the IEBC Letter with Stamped Plans.",
        "New stamp for Louisiana has been updated and implemented successfully"
      ]
    }
  ]
};
