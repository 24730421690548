export default {
  equipment: {
    weight: {
      value: null,
      unit: "lbs"
    },
    height: {
      value: null,
      unit: "in"
    },
    width: {
      value: null,
      unit: "in"
    },
    length: {
      value: null,
      unit: "in"
    }
  },
  anchor: {
    s1: {
      value: null,
      unit: "in"
    },
    s2: {
      value: null,
      unit: "in"
    },
    d1: {
      value: null,
      unit: "in"
    },
    d2: {
      value: null,
      unit: "in"
    }
  }
};
