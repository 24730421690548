import _ from "lodash";

const initialState = () => ({
  data: null,
  initial_data: null,
  on_edit: false,
  is_loading: false,
  is_processing: false,
  is_pdf_rendering: false,
  override_readonly: false,
  show_notice: false,
  notice_msg: "",
  title: "",
  pdf: null,
  items_copy: [],
  addons_copy: []
});

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    SET_INVOICE: (state, payload) => {
      state.data = _.cloneDeep(payload);
      state.initial_data = _.cloneDeep(payload);
    },
    SET_COPIES: (state, payload) => {
      const items = _.cloneDeep(payload.items);
      const addons = _.cloneDeep(payload.addons);

      const items_copy = items.map(item => {
        item.cost = parseFloat(item.cost);
        return item;
      });

      const addons_copy = addons.map(item => {
        item.cost = parseFloat(item.cost);
        return item;
      });

      state.items_copy = items_copy;
      state.addons_copy = addons_copy;
    },
    REVERT_INVOICE: state => (state.data = _.cloneDeep(state.initial_data)),
    SET_ON_EDIT: (state, payload) => (state.on_edit = payload),
    SET_LOADING: (state, payload) => (state.is_loading = payload),
    SET_PROCESSING: (state, payload) => (state.is_processing = payload),
    SET_RENDERING: (state, payload) => (state.is_pdf_rendering = payload),
    SET_TITLE: (state, payload) => (state.title = payload),
    SET_OVERRIDE_READONLY: (state, payload) =>
      (state.override_readonly = payload),
    SET_SHOW_NOTICE: (state, payload) => {
      const { show, notice } = payload;
      state.show_notice = show;
      state.notice_msg = notice;
    },
    SET_PDF: (state, payload) => (state.pdf = payload),
    ADD_ITEM: (state, { item_number }) => {
      state.items_copy.push({
        id: null,
        item_number,
        name: "",
        cost: 0
      });
    },
    UPDATE_ITEMS_COPY: (state, payload) => {
      state.items_copy = payload;
    },
    UPDATE_ADDONS_COPY: (state, payload) => {
      state.addons_copy = payload;
    },
    UPDATE_INVOICE: (state, payload) => {
      state.data = payload;
    }
  },
  actions: {
    makeACopy: ({ commit, state }) => {
      commit("SET_COPIES", state.initial_data);
    }
  }
};
