import _concat from "lodash/concat";
import authentication from "./authentication";
import verification from "./verification";
import forgotPassword from "./forgotPassword";
import projects from "./projects";
import transactions from "./transactions";
import clients from "./clients";
import ahj from "./ahj";
import users from "./users";
import faq from "./faq";
import contacts from "./contacts";
import error423 from "./error/error423";
import error404 from "./error/error404";
import error408 from "./error/error408";
import error500 from "./error/error500";
import validateAuthentication from "@/middleware/validateAuthentication";
import redirectIfAuthorized from "@/middleware/redirectIfAuthorized";
import appMaintenance from "@/middleware/appMaintenance";
import createProject from "./createProject";
import projectImporter from "./projectImporter";
// import tutorial from "./tutorial";
import profile from "./profile";
import patchNotes from "./patchNotes";
// import saasAgreement from "./_saasAgreement";

const routes = [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: _concat(
      authentication,
      verification,
      forgotPassword,
      createProject,
      projects,
      transactions,
      clients,
      ahj,
      users,
      faq,
      contacts,
      projectImporter,
      // tutorial,
      profile,
      patchNotes,
      // saasAgreement,
      error404,
      error423,
      error408,
      error500
    ),
    meta: {
      middleware: [appMaintenance, validateAuthentication, redirectIfAuthorized]
    }
  }
];

export default routes;
