export default {
  date: "7-12-23",
  env_restriction: [],
  content: [
    {
      module: "Fixes",
      updates: [
        "Fix Issue with Hidden Files for Clients",
        "Clean Up Billing Cost Summary Page",
        "Updated the Patch Notes Page with the most recent bug fixes and enhancements",
        'Replaced banner verbiage: "thru" with the word "through"',
        'Fix Project Upgrade Issue with "Error 500"',
        "Remove Deprecated endpoint called across portal",
        "Aligned itemization in Project Overview",
        "Update Invoice Details Headers",
        "Fixed issue with the wet stamp linking to old project",
        "Fixed issue with project type/status not updating realtime after a project upgrade",
        "Update Verbiage for Error Page 404",
        "Remove Deprecated values for Roof Material Type",
        "Update Font sizes for Project Upgrade Cost summary screen"
      ],
      internal: [
        "Super Admin is now able to edit client balance in Portal",
        "Deltek Additional Project Types",
        "Update Missing Billing details"
      ]
    }
  ]
};
