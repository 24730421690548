export default {
  date: "4-26-2023",
  env_restriction: ["dev", "production"],
  content: [
    {
      module: "Bug Fixes",
      updates: [
        "Fixed an issue with Add-Ons Summary incorrectly displaying the cost for Wet Stamp Requests.",
        "Fixed an issue with the Users/Group tab not loading user data.",
        "Fixed an issue with the URL for a specific project. It is now showing the correct Project Number.",
        "Fixed an issue when upgrading to Full Calculations where the upgrade would not appear on the transaction’s history page.",
        "Fixed an issue with the “upload progress bar” being visible during Manual positioning of stamp.",
        "Fixed an issue with the building description drop down list being duplicated during project creation.",
        "Fixed an issue where users could not save changes to their profile details.",
        "Fixed an issue with the project list page causing the webpage not to load.",
        "Fixed an issue with the project filter on the project list page. It is now correctly sorted by submittal date, project type, etc.",
        "Fixed an issue with the FedEx tracking number link. It is now properly redirecting users to the FedEx page."
      ]
    },
    {
      module: "Project Overview",
      updates: [
        "Placeholder text for service descriptions has been updated on the Upgrade Project page.",
        "Decimals places have been removed from the Live Load field on the project overview page.",
        "Users remaining Wet stamp requests are now shown during the project add-Ons screen and on the Wet stamp tab ",
        "Project Name and Client Project Number now have a character limit of 100 characters when editing the project information."
      ]
    },
    {
      module: "Project Creation",
      updates: [
        "Project Name and Client Project Number now have a character limit of 100 characters during project creation.",
        'The Building Description dropdown list has been updated to "Residential House" and "Detached Garage."',
        'Surrounding Terrain Dropdown options have been updated. The "other" and "I don’t know" options have been removed.',
        "Users can no longer enter a roof slope over 45°. When a user enters a roof slope of 45° an error message will appear, and you will not be able to proceed with the project."
      ]
    },
    {
      module: "Project List",
      updates: [
        '"Projects" on the Project List Page will now be sorted by default to the most recent Submittal Date.',
        "A notification has been added before a user can archive the active Primary document/Plan set.",
        "Updated UI to make the currently active sort more noticeable on the project list page."
      ]
    }
  ]
};
