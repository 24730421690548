export default {
  date: "5-19-2023",
  env_restriction: ["production"],
  content: [
    {
      module: "Hotfix Applied",
      updates: [
        'Fixed an issue with "Miscellaneous File Upload" API call. Files will now follow proper file extension formatting.',
        'Fixed an issue with the "Create Project" API call causing longer runtimes then expected. Users should see a significant improvement.',
        'Removed Deltek ID from the "Create Project" API call response.',
        "Fixed an issue with file uploads causing a re-stamp cost to be incurred before the project was completed. "
      ]
    },
    {
      module: "Feature",
      updates: [
        "Patch Notes Modal and Page created to show bug fixes and feature updates we have deployed."
      ]
    }
  ]
};
