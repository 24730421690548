import api from "@/api";

const initialState = () => ({
  step: 1,
  formdata: {
    first_name: "",
    last_name: "",
    company_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    email: "",
    website: "",
    phone_number: "",
    password: "",
    password_confirmation: ""
  }
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    SET_STEP: (state, payload) => (state.step = payload),
    SET_FORMDATA: (state, payload) => (state.formdata = payload),
    RESET_STATE: state => Object.assign(state, initialState())
  },
  actions: {
    register: async (context, payload) => {
      // eslint-disable-next-line no-useless-catch
      try {
        return await api.post("/auth/register", payload);
      } catch (error) {
        throw error;
      }
    }
  }
};
