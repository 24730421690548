export default {
  date: "12-19-23",
  env_restriction: [],
  content: [
    {
      module: "Features",
      updates: [
        "Added a Holiday Banner for January 1, 2024.",
        'Introduced an option to hide the Tour/Tutorial modal; users can select "Do Not Show Again" to prevent it from appearing on subsequent logins.',
        "Unveiled a new icon design for the Project List and Transactions tab to enhance distinctiveness.",
        "Added a holiday and work hour schedule for Christmas."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Fixed a navigation issue on the Transactions page, which previously showed only the most recent transaction. Further improvements include navigation enhancements, highlighting the current page number for clearer user understanding.",
        "Resolved the issue with the Company Dropdown in Groups, hiding the PZSE role from Clients. User creation under a client will now default to the company added by the admin. The Groups dropdown will show only the selected Company for PZSE roles, and a different Group dropdown under the Clients group will display only the groups associated with them.",
        "Added a Deltek column to exported .csv and .xlsx files for PZSE roles.",
        "Fixed the issue with IEBC SP not converting to IEBC after the upgrade.",
        "Made updates to the Project list, such as alignment, spacing, and wrap issue corrections. Added a Zip code to addresses and removed the Legacy Column.",
        'Updated the naming convention of the Users Tab to Accounts Tab to eliminate redundancy in the term "User."',
        "Resolved the issue where upgrade notifications were not being sent via push or email to PZSE Project Coordinators.",
        "Fixed the issue with the IEBC download that was removed in a previous deployment. PZSE account profiles can now preview and download the IEBC letter.",
        "Updated the North Carolina Stamp.",
        "Resolved the issue where PZSE Project Coordinators were unable to upload files for certain projects."
      ]
    }
  ]
};
