import validateLegacyProject from "@/middleware/validateLegacyProject";

export default {
  name: "ProjectImporterOverview",
  path: ":id/overview",
  component: () => import("@/views/ProjectImporterOverview"),
  props: true,
  meta: {
    middleware: [validateLegacyProject]
  }
};
