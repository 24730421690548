import _ from "lodash";
import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import routes from "./routes";
import initProgress from "@/progressbar";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
  mode: "history"
});

/**
 * Creates a `nextMiddleware()` function which not only runs the default `next()` callback but also triggers the `subsequentMiddleware()`.
 *
 * @param context
 * @param middleware
 * @param index
 * @returns {(function(...[*]=))|*}
 */
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  // If no subsequentMiddleware exists, the default `next()` callback is returned.
  if (!subsequentMiddleware) {
    return context.next;
  }

  return (...parameters) => {
    _.each(parameters, item => {
      // If set in `next({ force: true })`.
      if (item.force) {
        context.next(item);
      }
    });

    /**
     * Then run the `subsequentMiddleware()` with a new `nextMiddleware()` callback.
     * @type {function(...[*]=)}
     */
    const nextMiddleware = nextFactory(context, middleware, index + 1);

    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

/**
 * Process middleware.
 *
 * @param from
 * @param to
 * @param next
 * @param middleware
 * @returns {*}
 */
function processMiddleware(from, to, next, middleware) {
  const context = { router, from, to, next };
  const nextMiddleware = nextFactory(context, middleware, 1);

  return middleware[0]({ ...context, next: nextMiddleware });
}

/**
 * Navigate route.
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
function navigate(to, from, next) {
  if (to.meta?.middleware_excemption) return next();

  store.commit("SET_IS_REDIRECTING", true);

  const middleware = [];

  to.matched.some(matched => {
    if (matched.meta.middleware && !_.isEmpty(matched.meta.middleware)) {
      for (const key in matched.meta.middleware) {
        const value = matched.meta.middleware[key];

        middleware.push(value);
      }
    }
  });

  if (!_.isEmpty(middleware)) {
    return processMiddleware(from, to, next, middleware);
  }

  return next();
}

/**
 * Global navigation guards.
 */
router.beforeEach((to, from, next) => navigate(to, from, next));

initProgress(router);

router.afterEach(() => store.commit("SET_IS_REDIRECTING", false));

export default router;
