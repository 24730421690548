import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    locations: [],
    isDataTableIconLoader: false,
    show_suspended: false
  },
  mutations: {
    SET_LOCATIONS_DATA: (state, payload) => (state.locations = payload),
    SET_IS_LOCATION_LOADING_ICON: (state, payload) =>
      (state.isDataTableIconLoader = payload.loading),
    SET_SHOW_SUSPENDED: (state, payload) => {
      state.show_suspended = payload.show_suspended;
    }
  },
  actions: {
    getLocations: async ({ state, commit }, pageNumber) => {
      let ConvertToBool = "";
      if (state.show_suspended) {
        ConvertToBool = 1;
      }

      let getLocationsData = await api.get(
        `/locations?page=${pageNumber}&per_page=10&show_suspended=${ConvertToBool}`
      );

      let locationsData = _.get(getLocationsData, "data.result", []);

      commit("SET_LOCATIONS_DATA", locationsData);
    },
    toggleLocationLoadingIcon: ({ commit }, payload) =>
      commit("SET_IS_LOCATION_LOADING_ICON", payload),
    toggleShowSuspended: ({ commit }, payload) =>
      commit("SET_SHOW_SUSPENDED", payload)
  }
};
