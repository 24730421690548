import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    proposal_logs: []
  },
  getters: {},
  mutations: {
    SET_PROPOSAL_LOGS: (state, payload) => (state.proposal_logs = payload)
  },
  actions: {
    getData: async ({ rootState, commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(
          `/project-proposals/logs/get-by-project/` +
            rootState.projectOverview.project.project.id
        );
        const proposal_logs = _.get(response, "data.result");

        commit("SET_PROPOSAL_LOGS", proposal_logs);

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
