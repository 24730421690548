import _concat from "lodash/concat";
import login from "./login";
import registration from "./registration";

export default {
  path: "/",
  redirect: "/login",
  component: () => import("@/layouts/AuthenticationLayout"),
  children: _concat(login, registration)
};
