import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    comments: [],
    formdata: {
      project_id: null,
      parent_id: null,
      content: "",
      type: "standard",
      status: "in-progress"
    },
    comment_replying: null,
    data_ready: false,
    comments_tab: ""
  },
  getters: {},
  mutations: {
    SET_FORMDATA: (state, payload) => {
      state.formdata = payload;
    },
    SET_COMMENTS: (state, payload) => {
      state.comments = payload;
    },
    SET_COMMENT_REPLYING: (state, payload) => {
      state.comment_replying = payload;

      if (payload) {
        state.formdata.parent_id = payload.id;
      } else {
        state.formdata.parent_id = null;
      }
    },
    SET_DATA_READY: (state, payload) => (state.data_ready = payload),
    SET_COMMENTS_TAB: (state, payload) => {
      state.comments_tab = payload;
      // override type if tab is internal
      if (_.isEmpty(payload)) {
        state.formdata.type = "standard";
      } else {
        state.formdata.type = "pzse-employee-internal";
      }
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async index({ rootState, state, commit }) {
      commit("SET_DATA_READY", false);

      const url = `/project-comments/${state.comments_tab ?? ""}`;

      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(url, {
          params: {
            project_id: rootState.projectOverview.project.project.id
          }
        });

        const unpacked_comment = _.get(response, "data.result", []);

        /**
         * this block of code can be remove
         * if the data structure of each comment
         * and the array of result of the api is adjusted in BE
         **/
        // For a single thread of comments/reply
        let comment_arr = [];

        // RESTRUCTURE comments/replies to add "parent" prop for each comment
        unpacked_comment.forEach(comment => {
          // prepare parent comment object
          const comment_obj = _.cloneDeep(comment);
          // remove to avoid redundant deep nested properties
          delete comment_obj.replies;
          comment_arr.push(comment_obj);

          const replies = comment?.replies ?? null;
          // set parent prop of each replies with the prepared parent comment
          replies.forEach(reply => {
            reply.parent = comment_obj;

            // check for duplicate and replace item for accuracy
            const index_of_dup = comment_arr.findIndex(c => c.id === reply.id);
            if (index_of_dup !== -1) {
              comment_arr.splice(index_of_dup, 1, reply);
            } else {
              comment_arr.push(reply);
            }
          });
        });
        /** this block of code can be remove **/

        commit("SET_COMMENTS", comment_arr);
        commit("SET_DATA_READY", true);
      } catch (error) {
        throw error;
      }
    },
    async store({ rootState, state }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const formdata = state.formdata;
        formdata.project_id = rootState.projectOverview.project.project.id;

        return await api.post("/project-comments", state.formdata);
      } catch (error) {
        throw error;
      }
    }
  }
};
