import validateResetPasswordToken from "@/middleware/validateResetPasswordToken";

export default {
  name: "NewPassword",
  path: "/reset-password",
  component: () => import("@/views/ForgotPassword/NewPassword"),
  meta: {
    middleware: [validateResetPasswordToken]
  }
};
