export default {
  date: "10-03-23",
  env_restriction: [],
  content: [
    {
      module: "Features",
      updates: [
        "Introduced an 'Edit Button' for coordinators and PZSE admin profiles to correct project addresses with ease. This feature is solely for correction purposes and enhances data accuracy by allowing quick fixes of address typos.",
        "Implemented issue resolution for the 'Update Project' API call that was previously resulting in a server error. The call update function will now successfully accommodate required fields: Name, Client Po, and Description. This capability is limited to PZSE only. In addition, modifications to any project details will now be unfeasible post project completion.",
        'Introduced an internal API GET "remaining restamps" and POST "Update remaining restamps" allowing coordinators to view and update the remaining restamp credits for each project.'
      ]
    },
    {
      module: "Bugs/Hot Fix",
      updates: [
        "Added a 'Redline Status' to projects which provides real-time updates to the request catcher website through webhook data, enhancing efficiency. Please note, the current available status event is 'Completed Project.",
        "Fixed a regression issue where the uploaded file disappears in the dropzone when navigating back and forth. Files will now persist in the dropzone enhancing uptake and project visibility. Multiple document uploads capability remains intact.",
        "Fixed the issue with 'IEBC' project types not correctly displaying the 'Roof Material Type'. Each 'IEBC' combination now reflects its corresponding 'Roof Material Type', enhancing information accuracy and reliability in the portal.",
        "Refactored the address display to eliminate redundancy in the 'Project List' and 'Address column', and in the generated IEBC Letter. Now the whole address will be shown only once for greater clarity and streamlined data presentation.",
        "Increased the page limit on the AHJ table from 15 to 50 entries per page, facilitating improved navigation and visibility. Tested performance, ensuring no lag or slowness when navigating pages."
      ]
    }
  ]
};
