import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    addons: []
  },
  getters: {},
  mutations: {
    SET_ADDONS: (state, payload) => (state.addons = payload)
  },
  actions: {
    getData: async ({ commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const param = {
          type: "project_addon"
        };

        const response = await api.get("/providers/services", param);
        const result = _.get(response, "data.result", []);

        commit("SET_ADDONS", result);

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
