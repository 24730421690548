import _concat from "lodash/concat";
import clients from "./clients";

//TODO: if possible change every name from "clients" to "accounts"
// currently unable due to fast-paced updates on clients folder
// ref: views, router, store
export default {
  path: "/accounts",
  component: () => import("@/layouts/FullLayout"),
  children: _concat(clients)
};
