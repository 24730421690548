import _ from "lodash";
import {
  wetStampOneToFour,
  wetStampFiveToEight
} from "@/helpers/projectAddons";

const initialState = () => ({
  fixed_selected_types: [],
  project_types: [],
  project_addons: [],
  shipping_profile_id: null,
  shipping_profiles: [],
  formdata: {
    project_types: [],
    project_addons: [],
    wet_stamps: 0,
    shipping_profile: {},
    set_default_shipping_profile: false
  },
  project_costs: {
    current_balance: 0,
    project_addons_cost: [],
    project_types_cost: [],
    remaining_balance: 0,
    total_cost: 0,
    total_project_addons_cost: 0,
    total_project_types_cost: 0
  },
  data_fetching: false
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    wet_stamp_price: state => {
      const count = state.formdata.wet_stamps;
      const wetstamp1to4 = wetStampOneToFour();
      const wetstamp5to8 = wetStampFiveToEight();

      if (_.inRange(count, 5, 9)) {
        return state.project_addons.find(addon => addon.id === wetstamp5to8.id);
      }

      return state.project_addons.find(addon => addon.id === wetstamp1to4.id);
    },
    is_wet_stamp_project_addon_selected: state => {
      const wetstamp1to4 = wetStampOneToFour();
      const wetstamp5to8 = wetStampFiveToEight();

      return !!state.formdata.project_addons.find(
        addon => addon.id === wetstamp1to4.id || addon.id === wetstamp5to8.id
      );
    }
  },
  mutations: {
    INITIALIZE_UPGRADE: (state, selected_project_types) => {
      let array = [];

      selected_project_types.forEach(item => {
        array.push(state.project_types.find(type => type.id === item));
      });

      state.formdata.project_types = _.cloneDeep(array);
      state.fixed_selected_types = _.cloneDeep(array);
    },

    SET_PROJECT_TYPES: (state, { project_types }) =>
      (state.project_types = project_types),

    SET_FORMDATA_PROJECT_TYPES: (state, { project_types }) =>
      (state.formdata.project_types = _.orderBy(
        project_types,
        ["name"],
        ["asc"]
      )),

    SET_FORMDATA_PROJECT_ADDONS: (state, { project_addons }) =>
      (state.formdata.project_addons = _.orderBy(
        project_addons,
        ["name"],
        ["asc"]
      )),

    SET_FORMDATA_SET_DEFAULT_SHIPPING_PROFILE: (
      state,
      { set_default_shipping_profile }
    ) =>
      (state.formdata.set_default_shipping_profile = set_default_shipping_profile),

    SET_SHIPPING_PROFILE_ID: (state, payload) =>
      (state.shipping_profile_id = payload),

    SET_FORMDATA_SHIPPING_PROFILE: (state, payload) =>
      (state.formdata.shipping_profile = payload),

    SET_SHIPPING_PROFILES: (state, payload) =>
      (state.shipping_profiles = payload),

    SET_FORMDATA_WET_STAMPS: (state, { wet_stamps }) =>
      (state.formdata.wet_stamps = wet_stamps),

    SET_PROJECT_ADDONS: (state, { project_addons }) =>
      (state.project_addons = project_addons),

    SET_PROJECT_COSTS: (state, { costs }) => (state.project_costs = costs),

    SET_INITIAL_STATE: state => Object.assign(state, initialState()),

    SET_DATA_FETCHING: (state, payload) => (state.data_fetching = payload)
  },
  actions: {}
};
