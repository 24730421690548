import _concat from "lodash/concat";
import requestForgotPasswordLink from "./requestForgotPasswordLink";
import newPassword from "./newPassword";
import newPasswordSuccess from "./newPasswordSuccess";

export default {
  name: "ForgotPassword",
  path: "/forgot-password",
  component: () => import("@/layouts/ForgotPasswordLayout"),
  children: _concat(requestForgotPasswordLink, newPassword, newPasswordSuccess)
};
