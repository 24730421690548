export default {
  date: "9-04-23",
  env_restriction: [],
  content: [
    {
      module: "Feature release",
      updates: [
        "Email notifications temporarily disabled to address client concerns and improve the system. Users can still access notifications via the Portal, using filters and searches for specific updates.",
        "Shipping Profile Names will still be system-generated and not required in API calls but will now be unique per address combination. When reusing the same address combination in API calls, the system will no longer create a new shipping profile instance. Instead, it will use the previous record.",
        "Implemented security enhancements to restrict consecutive login and password reset attempts. Now, after 5 unsuccessful login attempts, an account is placed on a 3-minute hold. Password reset attempts are also restricted to prevent abuse. Multiple browser usage is now blocked for repeated attempts.",
        'MISC File Upload Status Update. For PZSE Admin/Coordinator/Engineer uploads, the project status remains unchanged. For Client Manager/Admin/User uploads, the status changes to "Forwarded to Engineer."',
        "Removed confirmation screens for Wet Stamp quantity and Final Project Submit. Confirmation screens remain for project upgrades.",
        'Improved directory display, when a project is moved to "Redlined" status by PZSE, the directory will now correctly show as "Redlines". Direct redline document upload fixed. This update applies to both internal and external projects, enhancing the redline process for all users.',
        "Added ModDate and DaysSinceUpdated to export. To import file, the first row of the CSV files needs to be removed otherwise import will fail."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Fixed issue regarding unable to save new user in Add New User.",
        "Fixed typo in Patch Notes page.",
        "Label is now aligned and is now showing IEBC Letter with Stamped Plans.",
        "Fix issue regarding skipping upgrade steps.",
        "Fixed issue where Shipping Profile doesnt populate after toggling the WS checkbox.",
        "Updated the word addons to Add Ons."
      ]
    },
    {
      module: "Additional Release",
      updates: [
        "Tabs are now more visible and easier to see.",
        'Removed document upload dropzone for all project types and validation dropzone during Create Project. Added Misc File Dropzone using Misc File Upload API. Removed all Document type values except Misc dropdown on Project Overview for the Client Side. Renamed "User Files" to "Project Files." Added "Misc" as the description for uploaded files. Temporarily removed the stamping feature.'
      ]
    }
  ]
};
