import _ from "lodash";
import api from "@/api";

const initial_user = {
  account: null,
  account_id: null,
  first_name: "",
  last_name: "",
  phone_number: "",
  role: null,
  user_role: null,
  address: {
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: ""
  },
  group: null,
  group_id: null,
  original_group: null,
  shipping_profile_id: null
};

export default {
  namespaced: true,
  state: {
    list: [],
    user: initial_user,
    show_suspended: false,
    account_id: null,
    term: "",
    meta: null,
    recipients: [],
    selected_recipients: []
  },
  mutations: {
    SET_SEARCH_TEXT: (state, payload) => {
      state.term = payload;
    },
    SET_USERS_DATA: (state, payload) => {
      state.list = payload.data;
      state.meta = payload.meta;
    },
    SET_INITIAL_STATE: state => {
      state.user_data = initial_user;
    },
    SET_USER_TO_EDIT: (state, payload) => {
      state.user = payload;
    },
    SET_SHOW_SUSPENDED: (state, payload) => {
      state.show_suspended = payload;
    },
    SET_RECIPIENTS: (state, payload) => {
      state.recipients = payload;
    },
    SET_SELECTED_RECIPIENTS: (state, payload) => {
      state.selected_recipients = payload;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async notifications({ commit }, { userId, take }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(
          `/users/${userId}/notifications/${take}`
        );

        return _.get(response, "data", {});
      } catch (error) {
        throw error;
      }
    },
    getUsers: async ({ state, commit }, page_number = 1) => {
      const users_data = await api.get("/users", {
        params: {
          page: page_number,
          per_page: 10,
          show_suspended: state.show_suspended ? 1 : "",
          term: state.term
        }
      });

      const data = _.get(users_data, "data.result", []);

      commit("SET_USERS_DATA", data);
    },
    getUsersByCompany: async ({ commit }, account_id) => {
      const users_data = await api.get("/users", {
        params: {
          page: 1,
          per_page: 100,
          account_id: account_id
        }
      });

      const data = _.get(users_data, "data.result.data", []);

      commit("SET_RECIPIENTS", data);
    },
    toggleShowSuspended: ({ commit }, payload) =>
      commit("SET_SHOW_SUSPENDED", payload)
  }
};
