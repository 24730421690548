export default {
  date: "12-05-23",
  env_restriction: [],
  content: [
    {
      module: "Features",
      updates: [
        "Implemented a Deltek Integration for setting up new clients. Coordinators will enter the Deltek Firm IDs on the portal, and this will be synced to the firm once verified.",
        'Added a checkbox that says "Do not show again" that users can select if they want the Patch Notes to not reappear everytime they login.',
        "Fixed the tab highlighting to avoid confusion to the clients as to which tab they are actively on.",
        "Added a + icon for Add Client, Add Group and Add User button to keep the tabs consistent and understandable.",
        "Added a feature to allow clients to preview the IEBC Content in Create Project and Project Overview documents tab. The preview has the watermarks to specify that the IEBC is a preview/sample only."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Alabama Stamp is now updated with 2025 expiry dates.",
        "Mississippi Stamp is now updated with 2025 expiry dates.",
        "Colorado Stamp is now updated with 2025 expiry dates.",
        "Fixed issue on Create Project page where users were able to go to next page when uploads have not yet completed. Now the project creation does not move to the next page unless all the files required had been uploaded.",
        "Fixed the pagination issue on Clients Page.",
        "Fixed the extra commas in the address, the alignment of columns and the address formatting on the Clients tab.",
        "Invoice tab is selected as a default for Client Users."
      ]
    }
  ]
};
