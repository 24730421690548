import _concat from "lodash/concat";
import projects from "./projects";
import projectsArchived from "./projectsArchived";
import projectOverview from "./projectOverview";
import pageAccessControls from "@/middleware/pageAccessControls";
import loadProjectInternalStatuses from "@/middleware/loadProjectInternalStatuses";
import processPatchNotes from "@/middleware/processPatchNotes";
import loadProjectAddons from "@/middleware/loadProjectAddons";

export default {
  path: "/projects",
  component: () => import("@/layouts/FullLayout"),
  children: _concat(projects, projectsArchived, projectOverview),
  meta: {
    middleware: [
      pageAccessControls,
      loadProjectInternalStatuses,
      loadProjectAddons,
      processPatchNotes
    ]
  }
};
