import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    project: {}
  },
  getters: {},
  mutations: {
    SET_LEGACY_PROJECT: (state, payload) => {
      state.project = payload;
    }
  },
  actions: {
    getData: async ({ commit }, id) => {
      const project = await api.get(`/project-importer/${id}`);
      let data = _.get(project, "data.result", []);

      commit("SET_LEGACY_PROJECT", data);
      return project;
    }
  }
};
