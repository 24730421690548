export default {
  date: "5-21-2023",
  // ["local", "dev", "staging", "training", "releasing", "production"]
  env_restriction: [],
  content: [
    {
      module: "Create Project",
      updates: [
        'Donec "use backslash to escape double quotes" molestie.',
        "Vestibulum ac diam sit amet quam vehicula.",
        'lorem ipsum dolor "sit" amet'
      ]
    },
    {
      module: "Project Overview",
      updates: [
        "elementum id enim.",
        "Vestibulum ac diam sit amet quam vehicula.",
        'lorem ipsum dolor "sit" amet'
      ]
    }
  ]
};
