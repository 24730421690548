export default {
  date: "6-24-23",
  env_restriction: [],
  content: [
    {
      module: "Fixes",
      updates: [
        "Fixed issue with   Project Completion and Project Redline email",
        "Updated Summary Breakdown in Create Project",
        "Added a feature for clients to be able to download the SAAS agreement before   accepting",
        "Recalibrated the   PZSE logo in the email notification",
        "Fixed issue with newly created accounts being unable to sign in",
        "Updated Transaction List page with: Client Project Number, PZSE Portal Number   and Address 1",
        "Fixed Page error when claiming a project",
        "Updated SAAS Agreement with the latest document",
        "Fixed default submittal sort for Project List",
        "Fixed display issue with adding credit cards in the transactions page",
        "Fixed issue in adding credit cards",
        "Updated returned error when the project number is not existing",
        "Fixed issue with Coordinators unable to download photos",
        "Fixed client issue with adding users"
      ]
    }
  ]
};
